import type {
  ProductLinePagedResult,
  ProductLineDetail,
  SlugTranslation,
  FileDetailPagedResult,
  ReferencePagedResult,
  FilePagedResult,
  NewsPagedResult,
  QueryParams,
} from '~/types/api'
import { APIFilters } from '~/utils/APIFilters'

export function createProductLineRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'product-lines'
  const DOMAIN_FILES = 'public-files'
  const DOMAIN_REFERENCES = 'public-references'
  const DOMAIN_IMAGES = 'public-images'
  const DOMAIN_TRANSLATE = 'translate'
  const DOMAIN_CATEGORIES = 'product-categories'
  const DOMAIN_PUBLIC_PRODUCT_LINES = 'public-product-lines'
  const DOMAIN_NEWS = 'public-news'

  async function getAll(params?: QueryParams) {
    if (!params) {
      params = {
        sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
      }
    }
    return get<ProductLinePagedResult>([DOMAIN], { params })
  }

  async function getAllPagination(
    page: number,
    itemsPerPage: number,
    categoryNid?: number
  ) {
    const params = {
      sort: APIFilters.makeSort({ weight: 'ASC', title: 'ASC' }),
      page,
      itemsPerPage,
    } as QueryParams

    if (categoryNid) {
      return get<ProductLinePagedResult>(
        [DOMAIN_CATEGORIES, categoryNid, DOMAIN_PUBLIC_PRODUCT_LINES],
        { params }
      )
    } else {
      return get<ProductLinePagedResult>([DOMAIN], { params })
    }
  }

  async function getByNid(nid: number) {
    return get<ProductLineDetail>([DOMAIN, nid])
  }

  async function getLineBySlug(slug: string) {
    return get<ProductLineDetail>([DOMAIN, slug])
  }

  async function getFiles(id: number) {
    return get<FileDetailPagedResult>([DOMAIN, id, DOMAIN_FILES])
  }

  async function translateSlug(slug: string) {
    return get<SlugTranslation>([DOMAIN, slug, DOMAIN_TRANSLATE])
  }

  async function getAllReferencesPagination(
    page: number,
    itemsPerPage: number,
    lineId: number
  ) {
    const params = {
      page,
      itemsPerPage,
      sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
    }
    return get<ReferencePagedResult>([DOMAIN, lineId, DOMAIN_REFERENCES], {
      params,
    })
  }

  async function getAllImagesPagination(
    page: number,
    itemsPerPage: number,
    lineId: number
  ) {
    const params = {
      page,
      itemsPerPage,
      sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
    }
    return get<FilePagedResult>([DOMAIN, lineId, DOMAIN_IMAGES], { params })
  }

  async function getAllNewsPagination(
    page: number,
    itemsPerPage: number,
    lineId: number
  ) {
    const params = {
      page,
      itemsPerPage,
      sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
    }
    return get<NewsPagedResult>([DOMAIN, lineId, DOMAIN_NEWS], { params })
  }

  return {
    getAll,
    getAllPagination,
    getByNid,
    getLineBySlug,
    getFiles,
    translateSlug,
    getAllReferencesPagination,
    getAllImagesPagination,
    getAllNewsPagination,
  }
}
